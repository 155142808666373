html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif;
}

canvas {
  width: 50vw !important;
  height: 100vh !important;
  z-index: 99999;
  position: fixed;
  box-sizing: border-box;
  background: rgb(243, 241, 236);
}

/* Mobile Portrait */
@media only screen and (max-device-width: 639px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  canvas {
    display: block;
    position: relative;
    width: 100vw !important;
    height: 150px !important;
  }
}

